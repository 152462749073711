import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.min.css';
import 'select2-bootstrap-theme/dist/select2-bootstrap.min.css';

const DEFAULT_OPTIONS = {
    theme: 'bootstrap',
    maximumSelectionSize: null,
    minimumResultsForSearch: 10,
    allowClearEnabled: false,
    width: 'auto',
};

export default class Select2Field {
    constructor(selector = 'select:not([data-sonata-select2="false"])') {
        this._selector = selector;
        this._$fields = null;

        $(document).on('fh:select2:reset', this.init);

        // Re-init select2 after adding an option with ModelType.
        $(document).on('sonata-admin-append-form-element', this.init);

        this.init();
    }

    init = () => {
        this._$fields = $(this._selector);

        if (!this._$fields.length) {
            return;
        }

        this._$fields.each((index, element) => {
            const $element = $(element),
                elData = $element.data();

            const hasAutocompleter = !!elData.select2Autocompleter,
                options = $.extend(
                    {},
                    DEFAULT_OPTIONS,
                    this._getElementSettings(elData),
                    hasAutocompleter ? this._createAutocompleterSettings(elData) : {}
                );
            $element
                .addClass('select2-initialized')
                .select2(options);
        });
    }

    _getElementSettings(elData) {
        return {
            maximumSelectionSize: elData.sonataSelect2MaximumSelectionSize,
            minimumResultsForSearch: elData.sonataSelect2MinimumResultsForSearch,
            allowClearEnabled: elData.sonataSelect2AllowClear === 'true'
        };
    }

    _createAutocompleterSettings(elementData) {
        return {
            ajax: {
                delay: 250,
                url: `${elementData.select2Remote}/search`,
                dataType: 'json',
                data: (params) => {
                    return {
                        autocompleter: elementData.select2Autocompleter,
                        q: params.term
                    };
                },
                processResults: (data) => {
                    // maintains backwards compatibility with the response from earlier versions :)
                    return {
                        results: data.items,
                        pagination: {
                            more: false
                        }
                    };
                }
            }
        }
    }

    get fields() {
        return this._$fields;
    }
};
