import $ from 'jquery';
import FHRedactor from 'fh-redactor/build/fhRedactorIII';
import 'fh-redactor/lib/redactorIII/scss/redactor.scss';
import 'fh-redactor/build/pluginsRedactorIII';
import { showErrorNotification } from '../notifications';

export default class RedactorIIIField {
    constructor(defaultSettings = {}) {
        this._defaultSettings = defaultSettings;

        $(document).on('fh:redactor:reset', this.init);

        this.init();
        this.handleUploadError = this.handleUploadError.bind(this);
    }

    handleUploadError({ error }) {
        showErrorNotification(`<p>Er is een fout opgetreden tijdens het uploaden:<br><br>${error}</p>`);
    }

    init = () => {
        this._redactorFields = document.querySelectorAll('[data-tool~=fh-redactor]');

        if (!this._redactorFields.length) {
            return;
        }

        this._redactorFields.forEach((element) => {
            new FHRedactor(element, {
                styles: false,
                linkNewTab: true,
                toolbarFixedTopOffset: 110,
                callbacks: {
                    image: {
                        uploadError: this.handleUploadError
                    },
                    file: {
                        uploadError: this.handleUploadError
                    }
                },
                ...this._defaultSettings
            });
        });
    }
}
