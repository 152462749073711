import $ from 'jquery';
import Sortable from 'sortablejs';

export default class Sort {
    constructor() {
        this.setSortable();
    }

    setSortable() {
        var $sortables = $('.js-sortable');

        if (!$sortables.length) {
            return;
        }

        $sortables.each((i, sortable) => {
            var $el = $(sortable),
                data = $el.data();

            Sortable.create(sortable, {
                connectWith: '.js-sortable-item',
                placeholder: 'sortable-item-placeholder',
                onEnd: (event) => {
                    var idx = [];
                    $el
                        .find('.js-sortable-item')
                        .each((k, el) => {
                            var id = $(el).data('id');
                            if (id !== null) {
                                idx.push(id);
                            }
                        });

                    $.ajax({
                        url: data.sortableUrl,
                        data: {
                            sortedIds: idx
                        },
                        success: (response) => {
                            const $alert = $('<div class="alert alert-success"><p>' + data.message + '</p></div>');

                            $alert.appendTo($el.data('alert-holder') ? $el.data('alert-holder') : '.js-alert-holder')
                                .delay(1000)
                                .fadeOut(() => {
                                    $($alert).remove();
                                });
                        }
                    });
                }
            });
        });
    }
};
