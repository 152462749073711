import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"

// More info about options can be found here: https://github.com/apvarun/toastify-js
export function showSuccessNotification(text, options) {
    Toastify({
        text,
        backgroundColor: "linear-gradient(to right, #4cae4c, #5cb85c)",
        ...options
    }).showToast();
}

export function showErrorNotification(text, options) {
    Toastify({
        text,
        backgroundColor: "linear-gradient(to right, #d43f3a, #d9534f)",
        ...options
    }).showToast();
}
