import FileUpload from 'fh-file-upload';
import Crop from 'fh-file-upload/js/crop';
import 'cropper/dist/cropper.css'; // todo fh-file-upload should import cropper.css in crop.scss
import 'fh-file-upload/scss/file-upload.scss';
import 'fh-file-upload/scss/crop.scss';

export default class ImageUploadField {
    constructor() {
        this.init();
    }

    init = () => {
        FileUpload.init();
        Crop.init();
    }
}
