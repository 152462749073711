import $ from 'jquery';

export default class DatePickerField {
    constructor(options = {}) {
        this.options = $.extend(true, {}, {
            selector: '.picker__input, .datepicker, .timepicker',
            initializedClass: 'datepicker-initialized',
            dateFormat: 'dd-mm-yyyy',
            submitFormat: 'yyyy-mm-dd',
            timeFormat: 'HH:i',
        }, options);

        this.$datePickers = $(this.options.selector);

        $(document).on('fh:datePicker:reset', this._load).trigger('fh:datePicker:reset');
    }

    _load = () => {
        if (!this.$datePickers.length) {
            return;
        }

        require.ensure([], (require) => {
            require('pickadate/lib/picker');
            require('pickadate/lib/picker.date');
            require('pickadate/lib/picker.time');
            require('pickadate/lib/themes/classic.css');
            require('pickadate/lib/themes/classic.date.css');
            require('pickadate/lib/themes/classic.time.css');

            this._setDateTimePickers();
        }, 'pickadate');
    }

    _setDateTimePickers() {
        var $dateTimePickers = $(this.options.selector).not('.' + this.options.initializedClass),
            $datePickers = $dateTimePickers.not('[type=time]'),
            $timePickers = $dateTimePickers.filter('[type=time]'),
            dateRanges = false;

        $timePickers.each((i, elem) => {
            var $el = $(elem),
                elData = $el.data();

            var options = $.extend({
                format: this.options.timeFormat
            }, elData);

            $el.pickatime(options);
        });

        $datePickers.each((i, elem) => {
            var $el = $(elem),
                elData = $el.data();

            var options = $.extend({
                format: this.options.dateFormat,
                formatSubmit: this.options.submitFormat
            }, elData);

            if ( elData.fromRange || elData.toRange ) {
                dateRanges = true;
            }

            $el.pickadate(options);
        });

        if ( dateRanges ) {
            // Loop through $datePickers again to set DateRanges
            $datePickers.each((i, elem) => {
                var $el = $(elem),
                    elData = $el.data();

                if ( elData.fromRange ) {
                    var $toPicker = $datePickers.filter('[data-to-range=' + elData.fromRange + ']');

                    if ( !$toPicker.length ) {
                        return;
                    }

                    var fromPicker = $el.pickadate('picker'),
                        toPicker = $toPicker.pickadate('picker');

                    if ( fromPicker.get('value') ) {
                        toPicker.set('min', fromPicker.get('select'));
                    }
                    if ( toPicker.get('value') ) {
                        fromPicker.set('max', toPicker.get('select'));
                    }

                    fromPicker.on('set', function(event) {
                        if ( event.select ) {
                            toPicker.set('min', fromPicker.get('select'));
                        } else if ( 'clear' in event ) {
                            toPicker.set('min', false);
                        }
                    });

                    toPicker.on('set', function(event) {
                        if ( event.select ) {
                            fromPicker.set('max', toPicker.get('select'));
                        } else if ( 'clear' in event ) {
                            fromPicker.set('max', false);
                        }
                    });
                }
            });
        }

        $dateTimePickers.addClass(this.options.initializedClass);
    }
}
