import $ from 'jquery';
import { onConfirm } from './confirmDialog';

/**
 * Extends Sonata base list table
 */
export default class ListTable {
    constructor(options = {}) {
        this.options = $.extend(true, {
            actions: {
                batchSelect: true,
                deleteAction: true,
            },
            selectors: {
                deleteRow: '.js-delete-table-row',
                batchSelect: '.js-batch-select'
            }
        }, options);

        this.init();
    }

    init() {
        const { actions } = this.options;

        if (actions.batchSelect) {
            this.setBatchSelect();
        }

        if (actions.deleteAction) {
            this.setDeleteAction();
        }
    }

    setBatchSelect() {
        const { selectors } = this.options;

        $(selectors.batchSelect).each((i, batchSelect) => {
            const $el = $(batchSelect),
                $children = $el.closest($el.data('target')).find(':checkbox').not(batchSelect);

            $el.on('change', () => {
                $children.prop('checked', $el.is(':checked'));
            });

            $children.on('change', (e) => {
                if (!$(e.currentTarget).prop('checked')) {
                    $el.prop('checked', false);
                }
            });
        });
    }

    setDeleteAction() {
        const { selectors } = this.options;

        const handleDeleteAction = (buttonElement) => {
            const $el = $(buttonElement),
                actionUrl = $el.attr('href'),
                csrfToken = $el.data('csrfToken');

            $.ajax({
                url: actionUrl,
                method: 'delete',
                data: {
                    _sonata_csrf_token: csrfToken
                },
                success: () => {
                    $el.closest('tr').fadeOut(() => {
                        $el.closest('tr').remove();
                    });
                }
            });
        }

        $(selectors.deleteRow).on('click', (event) => {
            event.preventDefault();
            onConfirm(handleDeleteAction.bind(this, event.currentTarget));
        });
    }
}
