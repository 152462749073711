import { initCoreModules } from 'fhadmin/js/core';
import RedactorIIIField from 'fhadmin/js/modules/fields/redactor-iii-field';
import Select2Field from 'fhadmin/js/modules/fields/select2-field';
import DatePickerField from 'fhadmin/js/modules/fields/date-picker-field';
import ImageUploadField from 'fhadmin/js/modules/fields/image-upload-field';
import ContactMomentButton from "./contact-moment-button";

function initFormCollectionFields() {
    const fields = document.querySelectorAll('.form-collection');

    if (fields.length) {
        import(
            /* webpackChunkName: "fh-form-collection" */ 'fhform/js/form-collection'
        );
    }
}

function initTooltip() {
    $('[data-toggle="tooltip"]').tooltip({
        container: 'body',
    });
}

function initFields() {
    new ContactMomentButton();
    new DatePickerField({ dateFormat: 'yyyy-mm-dd' });
    new ImageUploadField();
    new RedactorIIIField({
        pastePlainText: true,
    });
    new Select2Field();
    initFormCollectionFields();
}

document.addEventListener('DOMContentLoaded', () => {
    initFields();
    initCoreModules();
    initTooltip();
});
