import bootbox from 'bootbox';

export function onConfirm(callbackFnc, options = {}) {
    bootbox.confirm({
        title: "Verwijderen?",
        message: "Weet je zeker dat je dit item wilt verwijderen?",
        buttons: {
            cancel: {
                label: '<i class="fa fa-times"></i> Annuleren'
            },
            confirm: {
                label: '<i class="fa fa-check"></i> Ja'
            }
        },
        callback: function (result) {
            if (result) {
                callbackFnc()
            }
        },
        ...options
    });
}
