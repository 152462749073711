export default class ContactMomentButton {
    constructor(selector = '.js-contact-moment') {
        this._selector = selector;

        this.init();
    }

    init() {
        const elements = document.querySelectorAll(this._selector);

        if (elements.length === 0) {
            return;
        }

        elements.forEach((element) => {
            element.addEventListener('click', async function(event) {
                event.preventDefault();

                const href = element.getAttribute('href');
                const response = await fetch(href);

                const parent = this.closest('.contact-moment-holder');

                parent.querySelector('.js-contact-moment-counter').textContent = await response.json();
            })
        });
    }
};
